import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useCallback, useState } from "react";
import { CardList } from "./components/cardList";
import { CardSearch } from "./components/cardSearch";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [cards, setCards] = useState([]);
  const handleCardSelect = useCallback(
    (card) => {
      console.log("card", card);
      const cardsArray = cards.slice();
      cardsArray.push(card);
      setCards(cardsArray);
    },
    [cards]
  );
  const handleCardDelete = useCallback(
    (index) => {
      const cardsArray = cards.slice();
      cardsArray.splice(index, 1);
      setCards(cardsArray);
    },
    [cards]
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <CardSearch onCardSelect={handleCardSelect} />
        </Box>
        <Box sx={{ my: 4 }}>
          <CardList cards={cards} onCardDelete={handleCardDelete} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
