import { Delete } from "@mui/icons-material";
import { Grid, IconButton, Link, Box } from "@mui/material";
import { useCallback } from "react";

export const CardList = (props) => {
  const cards = props.cards;
  const onCardDelete = props.onCardDelete;

  const handleDelete = useCallback(
    (index) => {
      onCardDelete(index);
    },
    [onCardDelete]
  );

  return (
    <Grid container spacing={2}>
      {cards.map((card, index) => {
        const isDoubleFaced =
          card.card_faces && !card.image_uris ? true : false;
        const cardImageUri = isDoubleFaced
          ? card.card_faces[0].image_uris.png
          : card.image_uris.png;
        // const cardBackImageUri = isDoubleFaced
        //   ? card.card_faces[1].image_uris.png
        //   : null;
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Link href={card.scryfall_uri} target="_blank" rel="noopener">
              <img
                src={cardImageUri}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </Link>
            <Box textAlign="center">
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(index)}
              >
                <Delete />
              </IconButton>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
