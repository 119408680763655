import { Autocomplete, Box, TextField } from "@mui/material";
import { useCallback, useState } from "react";

export const CardSearch = (props) => {
  const [cardName, setCardName] = useState(null);
  const [cardSearch, setCardSearch] = useState("");
  const [options, setOptions] = useState([]);
  const onCardSelect = props.onCardSelect;

  const handleSearch = useCallback((event, newValue) => {
    if (newValue === "") {
      setCardName(null);
    }
    setCardSearch(newValue);
    async function getCards() {
      const response = await fetch(
        "https://api.scryfall.com/cards/autocomplete?q=" + newValue
      );
      const cards = await response.json();
      setOptions(cards.data);
    }
    getCards();
  }, []);

  const handleSelect = useCallback(
    (event, newValue) => {
      setCardName(newValue);
      if (newValue !== null) {
        async function getCard() {
          const response = await fetch(
            "https://api.scryfall.com/cards/named?exact=" + newValue
          );
          const card = await response.json();
          onCardSelect(card);
          setCardName(null);
        }
        getCard();
      }
    },
    [onCardSelect]
  );

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={options}
        filterOptions={(x) => x}
        onInputChange={handleSearch}
        inputValue={cardSearch}
        value={cardName}
        onChange={handleSelect}
        renderInput={(params) => (
          <TextField {...params} label="Choose a card..." />
        )}
      />
    </Box>
  );
};
